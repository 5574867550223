export interface ApproverDelegateeConstructor {
  id: number
  firstName: string
  lastName: string
  expectedReturnDate: Nullable<ISODateString>
}

export class ApproverDelegatee {
  id: number
  firstName: string
  lastName: string
  expectedReturnDate: Nullable<ISODateString>
  // For backwards compatibility with models/User
  // tslint:disable-next-line:variable-name
  expected_return_date: Nullable<ISODateString>

  constructor(data: ApproverDelegateeConstructor) {
    this.id = data.id
    this.firstName = data.firstName
    this.lastName = data.lastName

    this.expectedReturnDate = data.expectedReturnDate
    this.expected_return_date = data.expectedReturnDate
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }
}
